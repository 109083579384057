import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { parseUrl } from 'query-string';
import useGlobal from '../store';
import Page from '../components/Page';
import Container from '../components/styles/Container';
import Heading from '../components/styles/Heading';
import Text from '../components/styles/Text';

const ActivatePage = ({ location }) => {
  const [, actions] = useGlobal();
  const { confirmEmail } = actions;

  const confirm = async (token, uid) => {
    await confirmEmail(token, uid);
  };

  useEffect(
    () => {
      if (location.search) {
        const qs = parseUrl(location.search).query;
        const { token, uid } = qs;
        return confirm(token, uid);
      }
      return null;
    },
    [location, confirmEmail]
  );

  return (
    <Page>
      <Container>
        <Heading>Activate Your Account</Heading>
        <Text>
          Thank you for choosing Modarri! Your account should be active shortly.
          Click the link to login
        </Text>
        <Link to="/login">Login</Link>
      </Container>
    </Page>
  );
};

ActivatePage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default ActivatePage;
